var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"groupForm",staticClass:"row"},[_c('div',{staticClass:"col-xs-8 offset-xs-2 col-md-8 offset-md-2 col-sm-12"},[_c('ValidationObserver',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.formTitle)+" ")])]),_c('div',{staticClass:"card-body"},[_c('ValidationProvider',{attrs:{"vid":"name","rules":"required","name":"The name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"Group Name","name":"name","fou":""},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"ident","rules":"required","name":"The ident"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"Group Ident","name":"name","fou":""},model:{value:(_vm.formData.ident),callback:function ($$v) {_vm.$set(_vm.formData, "ident", $$v)},expression:"formData.ident"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"forum_id","rules":"required","name":"The Edition"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-select',{attrs:{"name":"forum_id","size":"large","filterable":"","clearable":"","placeholder":"Edition","error":failed ? errors[0]: null,"input-classes":'select-default',"label":'Edition',"list":_vm.forumList,"listItemLabel":'name',"listItemValue":'id'},model:{value:(_vm.formData.forum_id),callback:function ($$v) {_vm.$set(_vm.formData, "forum_id", $$v)},expression:"formData.forum_id"}})]}}],null,true)})],1),_c('div',{staticClass:"card-footer text-right"},[_c('l-button',{attrs:{"disabled":_vm.entityNotFoundError || _vm.submitting,"nativeType":"submit","type":"info","wide":""},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_vm._v("Submit ")]),_c('l-button',{attrs:{"type":"danger","wide":""},on:{"click":function($event){return _vm.$router.push('/panel-groups/list')}}},[_vm._v("Cancel ")])],1)])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }